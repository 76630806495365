import "core-js/modules/es.number.constructor.js";
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, reactive, onMounted, nextTick } from "vue";
import FsLightbox from "fslightbox-vue/v3";
import { watch } from "@vue/runtime-core";
export default {
  __name: 'MFsLightbox',
  props: {
    modelValue: Boolean,
    sources: Array,
    slide: Number
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    /**
     * fslightbox 套件
     * 
     * 缺點: 很多功能都是pro才有
     */

    var cData = reactive({
      /**
       * @see https://fslightbox.com/vue/documentation/updating-props
       * The lightbox is not reactive to props changes other than "toggler" 
       * and slide control props. The lightbox component has to be 
       * remounted to update them. 
       * In most cases the best solution is to use the Vue's "key" prop—after updating this prop,
       *  Vue remounts the component.
       */
      random: new Date().getTime(),
      show: props.modelValue || false
    });

    function updateRadom() {
      cData.random = new Date().getTime();
    }

    function onClose() {
      cData.show = false;
      updateRadom();
    }

    function onOpen() {} // 外部


    watch(function () {
      return props.modelValue;
    }, function () {
      cData.show = props.modelValue;
    }); // 內部

    watch(function () {
      return cData.show;
    }, function () {
      emit('update:modelValue', cData.show);
    });
    onMounted(function () {});
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(FsLightbox), {
        class: "m-fs-lightbox",
        key: cData.random,
        toggler: cData.show,
        onClose: onClose,
        onOpen: onOpen,
        sources: __props.sources,
        slide: __props.slide
      }, null, 8, ["toggler", "sources", "slide"]);
    };
  }
};